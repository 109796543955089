import PropTypes from "prop-types";
import { useQuery } from "@apollo/client/react/hooks";
import Head from "components/shared/head/Head";
import Layout from "components/shared/layout/Layout";

import { GetPage } from "../../../helpers/api/cadeauConcepten/queries/pages.gql";

function PageLayout({
    siteName,
    title,
    description,
    keywords,
    slug,
    children
}) {
    let metadata;
    const { data, loading } = useQuery(GetPage, {
        variables: {
            slug
        },
        skip: !process.env.featureFlags.cmsData
    });

    if (!loading && data?.page?.metadata) {
        metadata = data?.page?.metadata;
    }

    return (
        <Layout>
            <Head
                siteName={metadata?.sitename || siteName}
                title={metadata?.title || title}
                description={metadata?.description || description}
                keywords={metadata?.keywords || keywords}
            />
            {children}
        </Layout>
    );
}

PageLayout.propTypes = {
    slug: PropTypes.string.isRequired,
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array,
    children: PropTypes.node.isRequired
};

PageLayout.defaultProps = {
    siteName: "Keuze Cadeaukaart",
    title: "De Nationale Keuze Cadeaukaart",
    description: "Dé cadeaukaart om alles te geven.",
    keywords: ["nationale", "keuze", "cadeau", "cadeaukaart"]
};

export default PageLayout;
