import PropTypes from "prop-types";
import { addApolloState, initializeApollo } from "lib/apolloClient";
import PageLayout from "components/shared/pageLayout/PageLayout";
import Landing from "components/landing/Landing";
import {
    GetLandingPage,
    GetLandingPages
} from "../helpers/api/cadeauConcepten/queries/landingpage.gql";

function LandingPage({ data, slug }) {
    const currentSlug = data?.page?.slug ?? slug;
    const metadata = data?.landingPage?.metadata ?? data?.page?.metadata;
    const title = metadata?.title ?? "";
    const description = metadata?.description ?? "";
    const keywords = metadata?.keywords ?? [];
    const pageData = data?.landingPage ?? undefined;

    return (
        <PageLayout
            slug={currentSlug}
            title={title}
            description={description}
            keywords={keywords}
        >
            <Landing data={pageData} />
        </PageLayout>
    );
}

LandingPage.defaultProps = {
    data: undefined
};

LandingPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
            metadata: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                keywords: PropTypes.arrayOf(PropTypes.string)
            }),
            seo: PropTypes.shape({
                seoTitle: PropTypes.string,
                seoText: PropTypes.string
            })
        }),
        landingPage: PropTypes.shape({
            hero: PropTypes.object,
            usps: PropTypes.array,
            steps: PropTypes.array,
            seo: PropTypes.shape({
                seoTitle: PropTypes.string,
                seoText: PropTypes.string
            }),
            metadata: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                keywords: PropTypes.arrayOf(PropTypes.string)
            }),
            received: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string
            }),
            sections: PropTypes.shape({
                resellers: PropTypes.bool,
                doubler: PropTypes.bool
            })
        })
    }),
    slug: PropTypes.string.isRequired
};

export async function getStaticProps({ params }) {
    const apolloClient = initializeApollo();
    const { slug } = params;

    try {
        const landingPageData = await apolloClient.query({
            query: GetLandingPage,
            variables: { slug }
        });

        const data = landingPageData?.data;

        if (!data) {
            return {
                notFound: true
            };
        }
        return addApolloState(apolloClient, { props: { data, slug } });
    } catch (error) {
        console.error(error); // eslint-disable-line
    }

    return { props: { slug } };
}

export async function getStaticPaths() {
    // Get the paths we want to pre-render based on posts
    const apolloClient = initializeApollo();

    const response = await apolloClient.query({ query: GetLandingPages });
    const paths =
        response?.data?.landingPages?.edges.map(page => ({
            params: { slug: page.slug }
        })) ?? [];

    // We'll pre-render only these paths at build time.
    // { fallback: blocking } will server-render pages
    // on-demand if the path doesn't exist.
    return { paths, fallback: "blocking" };
}

export default LandingPage;
